
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jaldi:wght@400;700&display=swap");

* {
  font-family: "Jaldi", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: white;
}

.jaldi-bold {
  font-family: "Jaldi", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.background-image {
  background-image: url("../public/img/Home.png");
  background-size: cover;
}


